<template>
  <div class="bio_link_main_view">

    <div class="view_inner d-flex flex-column">

      <div class="view_top d-flex align-items-center">
        <h4 class="title">Select Social Networks</h4>
        <router-link class="cross_icon ml-auto" :to="{name: 'bio_links'}">
          <i class="fal fa-times"></i>
        </router-link>
      </div>

      <div class="view_center">
        <div class="steps_dot mb-3">
          <button @click="$router.push({name: 'select_content'})"
                  class="btn---cta light-blue btn-round">
            <span>Go Back</span>
          </button>
          <div class="circles">
            <span class="dotted_circle active"></span>
            <span class="dotted_circle"></span>
          </div>
          <button @click="$router.push({name: 'content_social_block'})"
                  :disabled="getBioLinkContentSectionAdd.channels_option.length ===0 "
                  class="btn---cta btn-blue btn-round">
            <span>Next</span>
          </button>


        </div>

        <div class="social-icon-list">
          <h3 class="block-heading">Select up to {{ limit + 1 }} social media platforms.</h3>
          <div class="checkbox-list-block">

            <div class="checkbox_input_image" v-for="(social, key) in socials" :key="key">
              <input type="checkbox" v-model="getBioLinkContentSectionAdd.channels_option"
                     :disabled="getBioLinkContentSectionAdd.channels_option.length > limit && getBioLinkContentSectionAdd.channels_option.indexOf(social.value) === -1"
                     :id="social.id"
                     :value="social.value">
              <label :for="social.id" class="checkbox_right" :data-cy="social.dataCy">
                <i :class="social.icon"></i>{{ social.name }}</label>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {mapGetters} from 'vuex'

export default ({
  data() {
    return {
      socials: [
        {id: 'instagram', value: 'instagram', dataCy: 'instagram', icon: 'ins fab fa-instagram', name: 'Instagram'},
        {id: 'twitter', value: 'twitter', dataCy: 'twitter', icon: 'tw fab fa-twitter', name: 'Twitter'},
        {id: 'facebook', value: 'facebook', dataCy: 'facebook', icon: 'fb fab fa-facebook-f', name: 'Facebook'},
        {id: 'youtube', value: 'youtube', dataCy: 'youtube', icon: 'youtube-red fab fa-youtube', name: 'Youtube'},
        {id: 'linkedin', value: 'linkedin', dataCy: 'linkedin', icon: 'linkedin fab fa-linkedin', name: 'LinkedIn'},
        {id: 'medium', value: 'medium', dataCy: 'medium', icon: 'medium fab fa-medium', name: 'Medium'},
        {id: 'twitch', value: 'twitch', dataCy: 'twitch', icon: 'twitch fab fa-twitch', name: 'Twitch'},
        {id: 'spotify', value: 'spotify', dataCy: 'spotify', icon: 'spotify fab fa-spotify', name: 'Spotify'},
        {id: 'soundcloud', value: 'soundcloud', dataCy: 'soundcloud', icon: 'soundcloud fab fa-soundcloud', name: 'SoundCloud'},
        {id: 'apple', value: 'apple', dataCy: 'apple', icon: 'apple fab fa-apple', name: 'Apple Music'},
        {id: 'pinterest', value: 'pinterest', dataCy: 'pinterest', icon: 'pinterest fab fa-pinterest', name: 'Pinterest'},
        {id: 'tiktok', value: 'tiktok', dataCy: 'tiktok', icon: 'tiktok fab fa-tiktok', name: 'TikTok'},
        {id: 'snapchat', value: 'snapchat', dataCy: 'snapchat', icon: 'snapchat fab fa-snapchat', name: 'Snapchat'}
      ],
      limit: 4
    }
  },
  created() {
    if (!this.getBioLinkAdd.first_step_verify) this.$router.push({name: 'select_content'})
  },
  computed: {
    ...mapGetters(['getBioLinkAdd', 'getBioLinkContentSectionAdd'])
  },
  watch: {}

})
</script>

<style scoped>
.bio_link_layout .bio_link_main_view .view_inner .view_center .steps_dot .circles {
  width: 520px;
  text-align: center;
  display: inline-block;
}
</style>
